<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Voltage Optimisation Commissioning Forms
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="getOM"
            :disabled="selectedRows.length === 0"
        >
          O&M
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="archiveSelected"
            :disabled="selectedRows.length === 0"
        >
          Archive
          <v-icon
              right
              dark
          >
            mdi-archive
          </v-icon>
        </v-btn>
        <v-row>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelCustomer"
                label="Customer"
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelSite"
                label="Site Address"
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="repLabelDate"
                label="Work Date"
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="selectedRows.length > 0" cols="3">
            <v-text-field
                v-model="partNum"
                label="Part No:"
                clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col  v-if="selectedRows.length > 0"  cols="12">
            <label>VO Units Fitted (Amps):</label>
            <v-row>
              <v-col cols="auto" v-for="size in modelSizes" :key="size">
                <v-checkbox
                    v-model="selectedSizes"
                    :label="size.toString()"
                    :value="size"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col  v-if="selectedRows.length > 0" cols="12">
            Cert PDF: <input type="file" @change="handleFileUpload" accept="application/pdf" />
          </v-col>
          <v-col  v-if="selectedRows.length > 0" cols="12">
            Diagram PDF: <input type="file" @change="handleDiagramUpload" accept="application/pdf" />
          </v-col>
        </v-row>
        <v-col></v-col>

        <v-data-table
            :headers="headers"
            :items="vocommissionings"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >

          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-card-title>
                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewVocommissioning(item.id)">
                  View Details
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
              </v-card-title>
              <v-card-title>
              <v-btn
                  color="blue"
                  dark
                  outlined
                  @click="archiveRecord(item)">
                <v-icon
                    dark
                >
                  mdi-archive-plus
                </v-icon>
              </v-btn>
              </v-card-title>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import {saveAs} from 'file-saver';


export default {
  name: "Vocommissionings",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      uploadedPdf: null,
      uploadedDiagram: null,
      repLabelCustomer: '',
      repLabelSite: '',
      repLabelDate: '',
      selectedSizes: [],
      partNum: '',
      modelSizes: [63, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2250, 3200],
      repVoSizes: [],
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Job Number',
          sortable: true,
          value: 'jobNumber',
          align: 'left',
        },
        {
          text: 'Engineer',
          sortable: true,
          value: 'engineerName',
          align: 'left',
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customerName',
          align: 'left',
        },
        {
          text: 'Site Location',
          sortable: true,
          value: 'siteAddress1',
          align: 'middle',
        },
        {
          text: 'Work Date',
          sortable: true,
          value: 'workDate',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('vocommissionings', {loadingVocommissionings : 'isFindPending'}),
    ...mapGetters('vocommissionings', {findVocommissioningsInStore : 'find'}),

    vocommissionings() {
      return this.findVocommissioningsInStore({ query: {archived: { $ne: 1 }}}).data || [];
    },
    selectedVocommissioningIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('vocommissionings', { findVocommissionings: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('vocommissionings/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    viewVocommissioning(id) {
      this.$router.push('/app/vocomissioning/'+id)
    },
    async archiveRecord(item) {
      item.archived = 1;
      item.patch();
    },
    async archiveSelected() {
      for (const vocommissioningId of this.selectedVocommissioningIds) {
        const { Vocommissioning } = this.$FeathersVuex.api
        const thisVocommissioning = await Vocommissioning.get(vocommissioningId)
        thisVocommissioning.archived = 1;
        thisVocommissioning.patch()
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Split to get the pure base64 part
        reader.onerror = error => reject(error);
      });
    },
    async getOM() {
      try {

        if (!this.uploadedPdf) {
          alert("Please upload a PDF file.");
          return;
        }

        const formData = new FormData();
        formData.append('uploadedPdf', this.uploadedPdf);

        if(this.uploadedDiagram != null) {
          formData.append('uploadedDiagram', this.uploadedDiagram);
        }

        // Construct the query parameters string
        const queryParams = new URLSearchParams({
          customer: this.repLabelCustomer,
          site: this.repLabelSite,
          date: this.repLabelDate,
          selectedSizes: this.selectedSizes.join(','),
          partNum: this.partNum,
          fileName: 'EmissisVOOM',
          deviceIds: this.selectedVocommissioningIds.join(',')
        }).toString();

        // POST request to the server with FormData and query parameters
        //const response = await fetch(`${process.env.VUE_APP_FEATHERS_WS_URL}reports/get-report/vo?${queryParams}`, {
        const response = await fetch(`${process.env.VUE_APP_FEATHERS_WS_URL}api/reports/get-report/?${queryParams}`, {
          method: 'POST',
          body: formData,
          "Content-Type": "application/json",
          headers: {
            "Authorization": "Bearer " + this.$store.state.auth.accessToken
          }
        });

        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, 'EmissisVOOM.pdf');
        } else {
          alert('Failed to generate the report.');
        }
      } catch (error) {
        console.error("Error in generating report: ", error);
        alert("An error occurred while processing the request.");
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        this.uploadedPdf = file;
      } else {
        alert("Please select a PDF file.");
      }
    },
    handleDiagramUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        this.uploadedDiagram = file;
      } else {
        alert("Please select a PDF file.");
      }
    },
  },
  mounted() {
    this.findVocommissionings();
  }
}
</script>

<style scoped>

</style>
